import { NextPageContext } from 'next';
import Router from 'next/router';

import { Routes } from '../../typings';
import config from '../config';

export async function redirectTo(
  ctx: NextPageContext,
  route: Routes,
): Promise<void> {
  if (config.isBrowser) {
    await Router.push(route);
  } else {
    ctx.res?.writeHead(302, { Location: route });
    ctx.res?.end();
  }
}
