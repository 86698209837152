import { ReactElement } from 'react';
import { CircularProgress } from '@mui/material';
import { Color } from '@legacy-components/theme';
import colors from '@legacy-components/theme/colors';
import { LoadingSpinnerContainer } from './styledComponents';

export enum SpinnerSizes {
  LARGE = 'large',
  SMALL = 'small',
}

interface Props {
  color?: Color;
  size?: SpinnerSizes;
}

const LoadingSpinner = ({
  color = Color.PRIMARY,
  size = SpinnerSizes.LARGE,
}: Props): ReactElement => (
  <LoadingSpinnerContainer>
    <CircularProgress
      sx={{ color: colors[color] }}
      size={size === SpinnerSizes.LARGE ? 40 : 20}
    />
  </LoadingSpinnerContainer>
);

export default LoadingSpinner;
